<template>
	<div id="loginmian">
		<div style="width: 100%">

			<div class="login-t">
				<router-link to="/">
					<img :src="logoUrl" style="height: 54px;" />
				</router-link>
			</div>
		</div>
		<div class="loginh">
			<img :src="homepageImg" class="imgsty"></img>
			<login :closeStaus="closeStaus" />
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import login from '@/components/login/login';
	import {
		mapActions
	} from "vuex";
	export default {
		components: {
			login
		},
		mounted() {
			this.getBgImg();
			this.clearInfo();
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		},
		data() {
			return {
				closeStaus: false,
				homepageImg: require("@/assets/login7.png"),
				logoUrl: '',
			}
		},
		computed: {
			...mapActions(["getLogoUrl"]),
		},
		methods: {
			clearInfo() {
				localStorage.clear();
				sessionStorage.clear();
				// 清空vuex登录信息
				this.$store.commit("setUser", null);
				this.$store.commit("setCarCount", 0);
				this.$store.commit("setUserInfo", null);
				this.$store.commit("setMenBList", null);
				this.$store.commit("setMenPList", null);
			},
			getBgImg() {
				this.ApiRequestPostNOMess('/api/mall/ebbase/ebadvertisement/get-login-ad-list', {
					fAppTypeID: this.fAppTypeID
				}).then(res => {
					if (res.obj.length != 0) {
						this.homepageImg = res.obj[0].fPath;
					}
				}, error => {});
			}
		}
	};
</script>
<style>
	.forminp {
		width: 100%;
	}

	.testf .el-input__inner {
		border-radius: 20px !important;
		width: 100% !important;
		height: 40px !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2 .el-input__inner {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		height: 40px !important;
		width: 100% !important;
		background-color: rgba(246, 246, 246, 1);
		border: 0;
	}

	.testf2,
	.el-input-group--prepend .el-input__inner,
	.el-input-group__append {
		border: 0;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	#loginmian .log-c {
		/* width: 350px;
		height: 355px; */
		float: right;
		background-color: #fff;
		position: relative;
	}
</style>
<style lang="scss" scoped="scoped">
	.head-1 {
		height: 20px;
		margin-top: 30px;
	}

	.head-1 a {
		font-size: 18px;
		font-weight: 700;
	}

	.logind-6 {
		overflow: hidden;
		margin-top: 18px;
		font-size: 12px;
		text-align: left;
		margin-left: 13px;
		margin-right: 13px;
		margin-bottom: 15px;
		height: 59px;
	}

	.spanC {
		padding-bottom: 4px;
		border-bottom: 4px solid #0173fe;
	}

	.logind-6p {
		margin-top: 3px;
		margin-bottom: 24px;
		color: #999999;
	}

	.logind-7 {
		padding-top: 7px;
		padding-left: 13px;
		padding-right: 13px;
		font-size: 13px;
		margin-bottom: 15px;
	}

	.logind-8 {
		border-top: 1px solid #e4e4e4;
		padding-top: 15px;
		color: #999999;
	}

	.logind-9 {
		width: 322px;
		position: absolute;
		bottom: 0;
		background: #f8f9fe;
		height: 34px;
		padding-left: 14px;
		padding-right: 14px;
		padding-top: 15px;
	}

	.logind-9-sp {
		font-size: 13px;
		color: #0173fe;
	}

	.floatd {
		float: right;
	}

	.loginp {
		margin-left: 15px;
		margin-top: 11px;
		font-size: 17px;
		font-weight: 700;
		cursor: pointer;
	}

	.logind {
		display: flex;
		margin-left: 32px;
		margin-top: 20px;
	}

	.logind-1 {
		padding: 6px;
		margin-right: 22px;
		width: 148px;
		height: 148px;
		border: 1px solid #e4e4e4;
	}

	.logind-2 {
		width: 93px;
		height: 162px;
	}

	.logind-3 {
		margin-top: 14px;
		font-size: 13px;
		text-align: center;
	}

	.lohp {
		margin-top: 3px;
		margin-bottom: 0;
	}

	.lohp1 {
		margin-top: 3px;
	}

	.logind-4 {
		color: #0099ff;
		margin-left: 3px;
		margin-right: 3px;
	}

	.logind-5 {
		margin-left: 13px;
		margin-top: 20px;
		margin-right: 13px;

		.el-form-item {
			margin-bottom: 13px;
		}
	}

	.btn {
		font-size: 15px;
		width: 100%;
		background-color: rgba(1, 115, 254, 1);
		color: #fff;
	}

	.m11 {
		margin-top: 11px;
	}

	.login-t {
		font-weight: 700;
		font-size: 18px;
		line-height: 80px;
		height: 80px;
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;


	}

	.login-t img {
		margin-top: 18px;
	}

	.loginh {
		width: 100%;
		min-height: 480px;
		// background: #ffd67d url(assets/login1.png) no-repeat center;
		background-size: 100%;
		position: relative;
	}

	.login-c {
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;
		padding-top: 34px;
	}

	.loginh .log-c {
		width: 350px;
		height: 403px;
		float: right;
		background-color: #fff;
		position: relative;
	}

	.imgsty {
		height: 100%;
		width: 100%;
		position: absolute;
	}
</style>
